.promo-block-advantages__block {
    display: flex;
    flex-direction: row;

    text-transform: uppercase;

    color: #FFF;

    font-size: 12px;
    font-weight: bold;

    flex-wrap: wrap;
    justify-content: space-between;
}

.promo-block-advantages__block-2,
.promo-block-advantages__block-3 {
    display: none;
}

.promo-block-main__container.slick-initialized .slick-slide {
    display: flex;
}

.promo-block-advantages__image,
.promo-block-advantages__description-box {
    height: 180px;

    flex-basis: 100%;
}

.promo-block-advantages__image {
    border-radius: 7px 7px 0px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.promo-block-advantages__image-1 {
    background-image: url(../images/promopage/img_tablet.png);
}

.promo-block-advantages__image-2 {
    background-image: url(../images/promopage/img_book.png);
}

.promo-block-advantages__image-3 {
    background-image: url(../images/promopage/img_phone.png);
}

.promo-block-advantages__description-box {
    display: inherit;

    border-radius: 0px 0px 7px 7px;
    background-color: #35b6de;

    flex-basis: 100%;
    align-items: center;
}

.promo-block-advantages__logotip {
    width: 133px;
    height: 28px;

    background-image: url(../images/promopage/logotip.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.promo-block-advantages__description {
    padding: 0px 20px;

    text-align: center;

    font-size: 12px;
    line-height: 1.35;
}

.promo-block-advantages__description-box-content {
    display: inherit;
    flex-direction: column;

    width: 100%;
    height: 85%;

    align-items: center;
    justify-content: space-between;
}

.promo-block-advantages__button {
    width: 120px;
    height: 30px;

    cursor: pointer;
    text-align: center;

    border-width: 1px;
    border-style: solid;
    border-color: #FFF;
    border-radius: 10px;
    background-color: #ef0040;

    font-size: 12px;
    line-height: 30px;
}

.promo-block-store__container {
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

.promo-block-store__description {
    padding: 0px 10px;

    text-align: center;
    text-transform: uppercase;

    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;

    flex-basis: 100%;
}

.promo-block-store__button_1,
.promo-block-store__button_2 {
    display: block;

    width: 120px;
    height: 36px;
    margin: 10px 10px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.promo-block-store__button_1 {
    background-image: url(../images/appstore_but.png);
}

.promo-block-store__button_2 {
    background-image: url(../images/but_googlestore.png);
}

.promo-block-main__container .slick-arrow {
    top: 40.5%;

    width: 40px;
    height: 40px;
    margin-top: 0px !important;
}

.promo-block-main__container .slick-next:before {
    left: 10px;
}

.promo-block-main__container .slick-prev:before {
    right: 10px;
}

@media (min-width: 480px) {
    .promo-block-advantages__image,
    .promo-block-advantages__description-box
    {
        height: 206px;

        flex-basis: 50%;
    }

    .promo-block-advantages__image {
        border-radius: 7px 0px 0px 7px;
    }

    .promo-block-advantages__description-box {
        border-radius: 0px 7px 7px 0px;
    }

    .promo-block-advantages__description-box-content {
        height: 85%;
    }

    .promo-block-advantages__logotip {
        width: 133px;
        height: 28px;
    }

    .promo-block-advantages__description {
        padding: 0px 40px 0px 20px;

        text-align: left;

        font-size: 12px;
    }

    .promo-block-store__container {
        height: 96px;
    }

    .promo-block-advantages__button {
        width: 120px;
        height: 30px;

        border-width: 1px;
        border-radius: 10px;

        font-size: 12px;
        line-height: 30px;
    }

    .promo-block-main__container .slick-arrow {
        top: 34.5%;
    }
}

@media (min-width: 720px) {
    .promo-block-advantages__description {
        width: 260px;
        padding: 0px 0px;

        text-align: left;

        font-size: 12px;
    }

    .promo-block-advantages__image,
    .promo-block-advantages__description-box
    {
        height: 360px;
    }

    .promo-block-advantages__image {
        border-radius: 15px 0px 0px 15px;
    }

    .promo-block-advantages__description-box {
        border-radius: 0px 15px 15px 0px;
    }

    .promo-block-advantages__description-box-content {
        height: 82%;
    }

    .promo-block-advantages__logotip {
        width: 266px;
        height: 55px;
    }

    .promo-block-advantages__description {
        font-size: 15px;
    }

    .promo-block-advantages__button {
        width: 197px;
        height: 54px;

        border-width: 3px;
        border-radius: 15px;

        font-size: 18px;
        line-height: 50px;
    }

    .promo-block-store__container {
        height: 140px;
    }

    .promo-block-store__description {
        padding: 0px 50px;

        font-size: 18px;
    }

    .promo-block-store__button_1,
    .promo-block-store__button_2 {
        width: 198px;
        height: 61px;
        margin: 12px 20px;
    }

    .promo-block-main__container .slick-arrow {
        top: 37%;

        width: 50px;
        height: 50px;
    }

    .promo-block-main__container .slick-next:before {
        left: 12px;
    }

    .promo-block-main__container .slick-prev:before {
        right: 12px;
    }
}

@media (min-width: 1020px) {
    .promo-block-main__container .slick-arrow {
        top: 39.5%;

        width: 42px;
        height: 42px;
    }
}
